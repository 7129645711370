import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled, { createGlobalStyle } from 'styled-components'
import normalize from 'normalize.css'
import logo from '../static/logo.png'
import SEO from '../components/seo'

const Page = styled.section`
  display: flex;
  padding: 0% 2.5em;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const GlobalStyle = createGlobalStyle`
   ${normalize}
    html {
        background: #191849;
        font-family: acumin-pro-wide, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    }

  p {
    font-size: 17px;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-weight: 100;
  }

  body {
    color: white;
  }

  h1 {
      font-size: 4.678em;
  }

  h3 {
      font-size: 2.441em;
      font-weight: normal;
  }

  ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
          font-size: 4.678em;
          line-height: 1.052em;
          margin-bottom: 6px;
          &:hover, &:active {
              color: #ff4747;
          }
      }
  }

  @media only screen and (max-width: 800px) {

    h3 {
      font-size: 1.953em;
      font-weight: normal;
  }

  ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
          font-size: 3.052em;
      }
  }
  }

  @media only screen and (max-width: 320px) {

    h3 {
      font-size: 1.5em;
      font-weight: normal;
  }

  ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
          font-size: 1.95em;
      }
  }
`

const Logo = styled.img`
  max-width: 66px;
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  z-index: 2;
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />
        <meta name="facebook-domain-verification" content="z76ezxolpfe1rktfu5xz5s74ynheh8" />
        <SEO title="Home" keywords={['Music Company', 'Record Label']} />
        <Page>{children}</Page>
        <Link to="/">
          <Logo src={logo} alt="Logo" />
        </Link>
      </>
    )}
  />
)

export default Layout
